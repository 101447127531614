import { getCookieAccount } from "@/lib/cookie";

import { createAxiosInstance } from "./repository";

const authorize = async (payload) => {
  const axios = createAxiosInstance();
  const response = await axios.post("/authorize", payload);
  return response;
};

const deleteToken = async () => {
  const { token } = getCookieAccount();
  const axios = createAxiosInstance(token);
  const response = await axios.delete("/token/delete");
  return response;
};

// TODO: 削除予定
const connectToMedia = async (payload) => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const axios = createAxiosInstance(token);
  const response = await axios.put(`/${hallId}/media/accounts/connect`, payload);
  return response;
};

const mailAuthenticate = async (payload) => {
  const { token } = getCookieAccount();
  const axios = createAxiosInstance(token);
  const response = await axios.post("/mail-authenticate", payload);
  return response;
};

export { authorize, deleteToken, connectToMedia, mailAuthenticate };
