import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "fairs";
  const { instance, index, store, show, update, destroy } = Repository(
    `${hallId}/${resource}`,
    token
  );
  return {
    index,
    store,
    show,
    update,
    destroy,
    showTemplate: () => show("template"),
    showRules: () => show("rules"),
    showMediaReservation: (fairId, payload) => instance.get(`${fairId}/media_reservation`, payload),
    linkGroup: (fairId, payload) => instance.put(`/${fairId}/group`, payload),
    publish: (fairId) => instance.post(`/${fairId}/publish`),
    unpublish: (fairId) => instance.post(`/${fairId}/unpublish`),
    indexOrder: () => instance.get("order"),
    updateOrder: (payload) => instance.put("order", payload),
    indexMediaOrder: () => instance.get("order/media"),
    updateMediaOrder: (payload) => instance.put("order/media", payload),
  };
};
